import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import { shouldSendEmail } from "../../utils";
import { ServerThisContext } from "@ollie-sports/react-bifrost";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function miscEmails__server__emailMaxPrepsExportToSelf(
  this: ServerThisContext,
  p: {
    exportString: string;
    subject: string;
    selfAccountId: string;
  }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const {
    injectedServerLibraries: { sendGrid }
  } = getServerHelpers();

  const account = await h.Account.getDoc(p.selfAccountId);

  if (!account) {
    throw new Error("Invalid selfAccountId supplied!");
  }
  if (shouldSendEmail(account.email)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: account.email,
      subject: p.subject,
      html: `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title>${this.translate({ defaultMessage: "Max Preps Export" })}</title>
  <meta name="description" content="${p.subject}">
  <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
</head>
<body>
<p>${this.translate({
        defaultMessage:
          "Attached to this email you will find your Max Preps stats export for this game. To get it uploaded into Max Preps, simply do the following"
      })}:</p>
<p>
  &bull; ${this.translate({ defaultMessage: "Login to your account at" })} <a href="https://maxpreps.com">MaxPreps.com</a>. <br/>
  &bull; ${this.translate({ defaultMessage: "On the navbar, select" })} <b>${this.translate({
        defaultMessage: "schedule"
      })}</b> ${this.translate({ defaultMessage: "and then" })} <b>${this.translate({
        defaultMessage: "games"
      })}</b> ${this.translate({ defaultMessage: "from the dropdown" })}. <br/>
  &bull; ${this.translate({ defaultMessage: "From the schedule page, select the" })} <b>${this.translate({
        defaultMessage: "green plus button"
      })}</b> ${this.translate({ defaultMessage: "next to this game" })}. <br/>
  &bull; ${this.translate({ defaultMessage: "Manually enter the final score (box score) and then select" })} <b>${this.translate({
        defaultMessage: "Save & Import Stats"
      })}</b>. <br/>
  &bull; ${this.translate({ defaultMessage: "Select" })} <b>${this.translate({
        defaultMessage: "Choose File"
      })}</b> (${this.translate({ defaultMessage: "located beneath the final score" })}) ${this.translate({
        defaultMessage: "to import the saved file"
      })}. <br/>
  &bull; ${this.translate({ defaultMessage: "Click" })} <b>${this.translate({ defaultMessage: "Import" })}</b>. <br/>
  &bull; ${this.translate({ defaultMessage: "Finalize your selection and then press" })} <b>${this.translate({
        defaultMessage: "Publish"
      })}</b>. <br/>
</p>
<p>${this.translate({ defaultMessage: "Once imported, stats will appear on your team's public page within 30 minutes!" })}</p>
</body>
</html>
`,
      attachments: [
        {
          content: Buffer.from(p.exportString).toString("base64"),
          filename: "max-preps-export.txt",
          type: "plain/text",
          disposition: "attachment",
          contentId: "mytext"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${account.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__emailMaxPrepsExportToSelf.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
