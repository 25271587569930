import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import {
  SoccerStatSnapshot,
  PrettyPlayer,
  SoccerGame,
  CalendarEntryGameScrimmage,
  PlayerStatKeys,
  TeamStatKeys,
  SoccerStatModes
} from "@ollie-sports/models";
import {
  getPlayerSoccerStatDefs,
  PlayerStatCategories,
  SoccerEventMemoryDB,
  getTeamSoccerStatDefs,
  TeamStatCategories
} from "../../soccer-logic";
import moment from "moment";
import { formatNumber, ObjectValues, shouldSendEmail } from "../../utils";
import { translate } from "@ollie-sports/i18n";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function miscEmails__server__emailCSVToSelf(p: {
  subject: string;
  selfAccountId: string;
  playerFileName: string;
  teamFileName: string;
  snapshot: SoccerStatSnapshot;
  players: PrettyPlayer[];
  memoryDB: SoccerEventMemoryDB;
  soccerGame: SoccerGame;
  teamName: string;
  opponentTeamName: string;
  calendarEntry: CalendarEntryGameScrimmage;
  locale: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const {
    injectedServerLibraries: { sendGrid }
  } = getServerHelpers();

  const account = await h.Account.getDoc(p.selfAccountId);

  if (!account) {
    throw new Error("Invalid selfAccountId supplied!");
  }

  const playerStatKeysToUse = ObjectValues(PlayerStatKeys).filter(
    key =>
      getPlayerSoccerStatDefs(p.locale)[key].tabCategory !== PlayerStatCategories.hidden &&
      (getPlayerSoccerStatDefs(p.locale)[key].statModes.includes(SoccerStatModes.individual) ||
        getPlayerSoccerStatDefs(p.locale)[key].statModes.includes(SoccerStatModes.team))
  );

  const dateString = moment(p.calendarEntry.startDateTime).format("MM-DD-YYYY");
  const playerStats = p.players
    .map(pl => {
      return [
        ...[
          `${pl.derived.accountInfo.firstName} ${pl.derived.accountInfo.lastName[0]}.`,
          p.teamName,
          p.opponentTeamName,
          dateString
        ],
        ...playerStatKeysToUse.map(playerStatKey => {
          return formatNumber(
            p.snapshot.playerStats[pl.player.id]?.[playerStatKey] ?? 0,
            getPlayerSoccerStatDefs(p.locale)[playerStatKey].stringFormatHint,
            { minutesOnly: true }
          );
        })
      ].join(",");
    })
    .join("\n");

  const playerExportString = `${[
    ...[
      translate.common(p.locale).Player,
      translate({ defaultMessage: "Team Name", serverLocale: p.locale }),
      translate({ defaultMessage: "Opponent Team Name", serverLocale: p.locale }),
      translate({ defaultMessage: "Game Date", serverLocale: p.locale })
    ],
    ...playerStatKeysToUse.map(key => {
      return getPlayerSoccerStatDefs(p.locale)[key].prettyName;
    })
  ].join(",")}\n${playerStats}`;

  const teamStatKeysToUse = ObjectValues(TeamStatKeys).filter(
    key =>
      getTeamSoccerStatDefs(p.locale)[key].tabCategory !== TeamStatCategories.hidden &&
      (getTeamSoccerStatDefs(p.locale)[key].statModes.includes(SoccerStatModes.individual) ||
        getTeamSoccerStatDefs(p.locale)[key].statModes.includes(SoccerStatModes.team))
  );

  const teamStats = ["ownTeam", "opponentTeam"]
    .map(team => {
      const dateStr = moment(p.calendarEntry.startDateTime).format("MM-DD-YYYY");
      const baseInfo = team === "ownTeam" ? [p.teamName, "FALSE", dateStr] : [p.opponentTeamName, "TRUE", dateString];
      return [
        ...baseInfo,
        ...[
          teamStatKeysToUse.map(key => {
            if (team === "ownTeam") {
              return formatNumber(p.snapshot.teamStats.ownTeam[key] || 0, getTeamSoccerStatDefs(p.locale)[key].stringFormatHint);
            } else {
              return formatNumber(
                p.snapshot.teamStats.opponentTeam[key] || 0,
                getTeamSoccerStatDefs(p.locale)[key].stringFormatHint
              );
            }
          })
        ]
      ].join(",");
    })
    .join("\n");

  teamStatKeysToUse
    .map(key => {
      return [
        getTeamSoccerStatDefs(p.locale)[key].prettyName,
        formatNumber(p.snapshot.teamStats.ownTeam[key] || 0, getTeamSoccerStatDefs(p.locale)[key].stringFormatHint),
        formatNumber(p.snapshot.teamStats.opponentTeam[key] || 0, getTeamSoccerStatDefs(p.locale)[key].stringFormatHint)
      ].join(",");
    })
    .join("\n");

  const teamExportString = `${[
    translate({ defaultMessage: "Team Name", serverLocale: p.locale }),
    translate({ defaultMessage: "Is Opponent Team", serverLocale: p.locale }),
    translate({ defaultMessage: "Game Date", serverLocale: p.locale }),
    ...ObjectValues(TeamStatKeys)
      .filter(key => getTeamSoccerStatDefs(p.locale)[key].tabCategory !== TeamStatCategories.hidden)
      .map(key => getTeamSoccerStatDefs(p.locale)[key].prettyName)
  ].join(",")}\n${teamStats}`;
  if (shouldSendEmail(account.email)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: account.email,
      subject: p.subject,
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>CSV Export</title>
    <meta name="description" content="${p.subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${translate({ defaultMessage: "Attached to this email you will find your exported stats.", serverLocale: p.locale })}</p>

    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(playerExportString).toString("base64"),
          filename: p.playerFileName,
          type: "application/csv",
          disposition: "attachment",
          contentId: "players"
        },
        {
          content: Buffer.from(teamExportString).toString("base64"),
          filename: p.teamFileName,
          type: "application/csv",
          disposition: "attachment",
          contentId: "team"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${account.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }
  // SERVER_ONLY_TOGGLE
}

miscEmails__server__emailCSVToSelf.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
